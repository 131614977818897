<template>
  <div
    :class="['pipeline-status', {
      'pipeline-status--icon-only': iconOnly,
    }]">
    <v-progress-circular
      v-if="status === 'running'"
      size="16"
      width="2"
      indeterminate/>
    <v-icon
      v-else
      :class="['pipeline-status__icon', `${status}-color`]">
      {{ getPipelineIcon(status) }}
    </v-icon>
    <span v-if="status === 'succeeded' && !iconOnly">
      {{ formatTimeAgo(updatedAt) }}
    </span>
    <span v-else-if="!iconOnly">
      {{ $t(`pipeline.status.${status}`) }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'CyPipelineStatus',
  props: {
    status: {
      type: String,
      required: true,
    },
    updatedAt: {
      type: Number,
      default: null,
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getPipelineIcon (pipelineStatus) {
      const icons = {
        failed: 'error',
        paused: 'pause_circle_filled',
        errored: 'warning',
        aborted: 'cancel',
        pending: 'pending',
        succeeded: 'check_circle',
      }

      return icons[pipelineStatus] || 'pending'
    },
    formatTimeAgo (timestamp) {
      return timestamp ? this.$date.$formatTimeAgo(timestamp) : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.pipeline-status {
  display: flex;
  align-items: center;
  color: cy-get-color('primary', 'light-2');
  line-height: 1;

  .v-progress-circular {
    margin-inline: 2px 4px;
    border-radius: 50%;
    background: cy-get-color('white');

    circle {
      stroke: cy-get-color('primary', 'light-2');
    }
  }

  &__icon {
    margin-right: 4px;
    font-size: 18px;

    &.failed-color { color: cy-get-color("build", "failed"); }
    &.succeeded-color { color: cy-get-color("build", "succeeded"); }
    &.errored-color { color: cy-get-color("build", "errored"); }
    &.aborted-color { color: cy-get-color("build", "aborted"); }
    &.pending-color { color: cy-get-color("build", "pending"); }
    &.paused-color { color: cy-get-color("build", "paused"); }
  }

  &--icon-only {
    .v-progress-circular {
      margin-inline: 0;
    }

    .pipeline-status__icon {
      margin-right: 0;
    }
  }
}
</style>
