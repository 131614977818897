<template>
  <ul
    :class="['env-component-list', {
      'env-component-list--card-mode': isCardMode,
      'env-component-list--list-mode': !isCardMode,
      'env-component-list--nested': nested,
    }]">
    <li
      :id="`env-component-${envCanonical}-${canonical}`"
      v-for="{ id, canonical, name, description, use_case: usecase, service_catalog: stack } in envComponents"
      :key="id"
      :class="[
        'env-component-list__item',
        { 'env-component-list__item--active': openActionsComponent === canonical },
      ]"
      @click="navigatoToComponent(envCanonical, canonical)">
      <v-menu
        v-if="stack.canonical"
        :key="displayMode"
        :activator="`#env-component-${envCanonical}-${canonical} .env-component-stack`"
        :attach="`#env-component-${envCanonical}-${canonical} .env-component-stack`"
        open-on-hover
        open-delay="200"
        close-delay="200"
        offset-y>
        <CyWizardServiceCard
          class="border-none"
          :max-width="440"
          data-cy="stack-card-summary"
          :service="stack"/>
      </v-menu>
      <CyStackAvatar
        :size="isCardMode ? '32px' : '24px'"
        :stack="stack"/>
      <div class="d-flex">
        <h3 class="env-component-name">
          {{ name }}
        </h3>
        <span
          v-if="!isCardMode"
          class="env-component-stack"
          @click="redirectToStack(stack.ref)">
          {{ `${stack.canonical}#${usecase}` }}
        </span>
      </div>
      <div
        v-if="isCardMode"
        class="env-component-description">
        {{ description }}
      </div>
      <div class="env-component-data">
        <div
          v-if="pipelinesByComponent[canonical]"
          class="env-component-pipeline">
          <CyPipelineStatus
            :status="pipelinesByComponent[canonical].status"
            :updated-at="pipelinesByComponent[canonical].updated_at"/>
        </div>
        <div
          v-if="isCardMode"
          class="env-component-stack"
          @click="redirectToStack(stack.ref)">
          <span>
            <v-icon
              small>
              fa-cubes
            </v-icon>
          </span>
          <span>
            {{ `${stack.canonical}#${usecase}` }}
          </span>
        </div>
      </div>
      <div class="env-component-actions">
        <div v-if="!_.isEmpty(getStartStopPipeline(canonical))">
          <CyButton
            v-has-rights-to="['CreateBuild', projectCanonical]"
            aria-label="Start"
            icon="play_arrow"
            variant="tertiary"
            theme="primary"
            :sm="!isCardMode"
            icon-only
            @click.prevent.stop="startStopEnvironment('start', canonical)"/>
          <CyButton
            v-has-rights-to="['CreateBuild', projectCanonical]"
            aria-label="Stop"
            icon="stop"
            variant="tertiary"
            theme="primary"
            :sm="!isCardMode"
            icon-only
            @click.prevent.stop="startStopEnvironment('stop', canonical)"/>
        </div>
        <CyMenu
          v-if="!_.$isEmpty($static.envComponentActions(envCanonical, canonical))"
          left
          offset-y
          :sm="!isCardMode"
          :items="_.map($static.envComponentActions(envCanonical, canonical), action => ({
            ...action,
            action: () => action.action({ stack, canonical, name, envCanonical }),
          }))"
          @input="onMenuToggle(canonical, $event)"/>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import CyPipelineStatus from '@/components/CyPipelineStatus.vue'
import CyStackAvatar from '@/components/CyStackAvatar.vue'
import CyWizardServiceCard from '@/components/CyWizardServiceCard.vue'
import { extractStartStopPipelines } from '@/utils/helpers'

export default {
  name: 'CyEnvComponentList',
  components: {
    CyStackAvatar,
    CyPipelineStatus,
    CyWizardServiceCard,
  },
  props: {
    projectCanonical: {
      type: String,
      default: '',
    },
    envCanonical: {
      type: String,
      required: true,
    },
    envComponents: {
      type: Array,
      required: true,
    },
    displayMode: {
      type: String,
      default: 'card',
    },
    nested: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    openActionsComponent: null,
    loading: {
      start: false,
      stop: false,
    },
  }),
  computed: {
    ...mapGetters('organization/project', [
      'envComponentPipeline',
    ]),
    ...mapState('organization/project', {
      pipelines: (state) => state.pipelines,
    }),
    $static () {
      const { canDisplay } = this.$cycloid.permissions
      return {
        envComponentActions: (envCanonical, componentCanonical) => [
          {
            icon: 'settings',
            label: `${this.$t('environment.component.settings')}...`,
            action: () => {
              this.$router.push({
                name: 'envComponentSettings',
                params: {
                  envCanonical,
                  componentCanonical,
                  backRouteTo: this.$route.name,
                },
              })
            },
            permissionKey: 'UpdateProject',
          },
          {
            divider: true,
          },
          {
            icon: 'delete',
            label: `${this.$t('environment.component.delete')}...`,
            color: 'error',
            action: (component) => this.onDeleteAction(component),
            permissionKey: 'UpdateProject',
          },
        ].filter(({ permissionKey }) => !permissionKey || canDisplay(permissionKey, this.projectCanonical)),
      }
    },
    isCardMode () {
      return this.displayMode === 'card'
    },
    pipelinesByComponent () {
      return this.envComponents.reduce((acc, component) => {
        acc[component.canonical] = this.envComponentPipeline(this.envCanonical, component.canonical)
        return acc
      }, {})
    },
  },
  methods: {
    ...mapActions('alerts', [
      'SHOW_ALERT',
    ]),
    redirectToStack (stackRef) {
      this.$router.push({ name: 'stack', params: { stackRef } })
    },
    navigatoToComponent (envCanonical, componentCanonical) {
      this.$router.push({ name: 'envComponentOverview', params: { envCanonical, componentCanonical } })
    },
    onDeleteAction (component) {
      this.$emit('delete', component)
    },
    onMenuToggle (component, open) {
      if (this.openActionsComponent && this.openActionsComponent !== component && !open) return
      this.openActionsComponent = open ? component : null
    },
    getStartStopPipeline (componentCanonical) {
      let pipeline = {}
      if (!_.isEmpty(this.pipelines)) {
        pipeline = extractStartStopPipelines(this.pipelines)?.startStop
          .find(({ project, environment, component }) => {
            return project.canonical === this.projectCanonical &&
              environment.canonical === this.envCanonical &&
              component.canonical === componentCanonical
          })
      }
      return pipeline
    },
    async startStopEnvironment (type, componentCanonical) {
      const pipeline = this.getStartStopPipeline(componentCanonical)
      const { projectCanonical, envCanonical, orgCanonical } = this
      const pipelineCanonical = pipeline.name
      this.loading[type] = true
      const { data } = await this.$cycloid.ydAPI.createBuild(orgCanonical, projectCanonical, envCanonical, componentCanonical, pipelineCanonical, type) || {}
      if (data) {
        const successMessage = type === 'start' ? 'started' : 'stopped'
        this.SHOW_ALERT({ type: 'success', content: this.$t(`alerts.success.environment.${successMessage}`) })
      }
      this.loading[type] = false
    },
  },
}
</script>

<style lang="scss" scoped>
.env-component-list {
  display: grid;
  grid-template-rows: 1fr;
  gap: 0.5rem;
  padding: 0;
  list-style: none;

  &__item {
    .env-component-actions {
      opacity: 0;
    }

    &--active,
    &:hover {
      background-color: cy-get-color('primary', 'light-5') !important;

      .env-component-actions {
        opacity: 1;
      }
    }
  }

  &--card-mode {
    .env-component-list__item {
      display: grid;
      position: relative;
      grid-template-columns: 32px 1fr;
      align-items: start;
      padding: 16px;
      column-gap: 1rem;
      transition: background-color 0.3s ease;
      border: 1px solid cy-get-color('primary', 'light-4');
      border-radius: 8px;
      background-color: cy-get-color('white');
      cursor: pointer;

      .cy-stack-avatar {
        grid-row: 1 / span 3;
        margin-top: 4px;
      }

      h3 {
        font-size: $font-size-default;
      }

      .env-component {
        &-description {
          margin-bottom: 12px;
        }

        &-data {
          display: flex;
        }

        &-stack {
          color: cy-get-color('primary', 'light-2');
          cursor: pointer;

          .v-icon {
            margin-right: 4px;
            color: cy-get-color('primary', 'light-3');
          }
        }

        &-actions {
          display: flex;
          position: absolute;
          top: $spacer-3;
          right: $spacer-2;
          align-items: center;
        }

        &-pipeline {
          display: flex;

          &::after {
            content: '•';
            margin-inline: 8px;
            color: cy-get-color('primary', 'light-4');
          }
        }

        &:hover {
          background-color: cy-get-color('primary', 'light-5');
        }
      }
    }
  }

  &--list-mode {
    .env-component-list__item {
      display: grid;
      position: relative;
      grid-template-columns: 24px minmax(300px, 1fr) 150px minmax(0, 15%);
      gap: 8px;
      align-items: center;
      margin: -4px 0;
      padding: 4px;
      transition: background-color 0.3s ease;
      border-radius: 4px;
      cursor: pointer;

      h3 {
        font-size: $font-size-default;
        font-weight: $font-weight-default;
      }

      .env-component {
        &-stack {
          color: cy-get-color('primary', 'light-2');
          cursor: pointer;

          &::before {
            content: '•';
            margin-inline: 6px 2px;
            color: cy-get-color('primary', 'light-4');
          }
        }

        &-actions {
          display: flex;
          position: absolute;
          right: $spacer-2;
          align-items: center;
        }
      }

      &:hover {
        background-color: cy-get-color('primary', 'light-5');
      }
    }

    &:not(.env-component-list--nested) {
      padding: 12px 8px;
      border: 1px solid cy-get-color('primary', 'light-4');
      border-radius: 8px;
      background-color: cy-get-color('white');
    }

    &.env-component-list--nested {
      padding-top: 8px;
      padding-bottom: 16px;

      .env-component-list__item {
        padding-left: 68px;
      }
    }
  }
}
</style>
